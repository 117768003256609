import React, { useState, useEffect } from 'react'
import { Seo, Breadcrumb, Swiper, Billboard } from '@cgtalks/common/components'
import { LiveStatus } from '@cgtalks/common/constant/type'
import { graphql, StaticQuery, navigate, Link } from 'gatsby'
import Layout from '../molecules/layout'
import { GatsbyImage } from 'gatsby-plugin-image'
import { TimeIcon } from '@cgtalks/common/components/icon'
import {
  ShareCgOnlineDetailHeader,
  BannerContainer,
  ShareCgOnlineArticle,
  ShareCgOnlineQrcode,
  ShareCgOnlineRecommend,
  ShareCgOnlineDetailContent,
  ShareCgOnlineArticleTitle,
  ShareCgOnlineArticleHeader,
  ShareCgOnlineRecommedMore,
  ShareOnlineDetailContentCard,
  ShareCgOnlineSilder,
  ShareCgOnlineDetailTime,
  ShareCgOnlineDetailTag,
  ShareCgOnlineDetailFooter,
  ShareCgOnlineContainer,
  LinkToPost,
  CgOnlineTag,
  LiveDetailButton,
} from './share-cg-online-detail.atom'
import { OnlineShareCard, GuestProfile } from '../molecules/main'
import { useMedia } from 'use-media'
import { getCurrentPageVideoStatus } from '../service/video'

const ShareCgOnlineDetail = ({
  location,
  pageContext: { previousSlug, previousTitle, nextSlug, nextTitle, sourceData, slug },
}) => {
  const [currentDetailData, setCurrentDetailData] = useState({})
  const {
    seoTitle,
    description,
    seoKeywords,
    specificTime,
    image,
    title,
    recommendPost,
    isExsitVisit,
    visiturl,
    tag,
    guests,
    artical,
    publishTime,
  } = sourceData
  const SwiperSwitch = ({ children, ...rest }) => {
    const onlineActivity = React.Children.map(children, (child) => {
      if (child) {
        return child
      }
    })
    return <Swiper {...rest}>{onlineActivity}</Swiper>
  }
  const isMobile = useMedia({ maxWidth: 768 })

  useEffect(() => {
    async function getCardData() {
      const result = await getCurrentPageVideoStatus([sourceData], true)
      setCurrentDetailData(result[0])
    }
    getCardData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceData])

  return (
    <StaticQuery
      query={graphql`
        query {
          qrcodePic: file(relativePath: { eq: "online/qrcode.png" }) {
            childImageSharp {
              gatsbyImageData(quality: 75, breakpoints: [1920])
            }
          }
          bannerBg: file(relativePath: { eq: "online/online-detail-bg.png" }) {
            ...fluidImage
          }
          bannerMbBg: file(relativePath: { eq: "online/online-detail-mb-bg.png" }) {
            ...fluidImage
          }
        }
      `}
      render={({ qrcodePic, bannerBg, bannerMbBg }) => (
        <Layout location={location}>
          <Seo
            title={`${seoTitle}-泛CG聚未来`}
            description={description}
            keywords={seoKeywords}
            sharePostSlug={`sharecg/${slug}.html`}
            shareImgSrc={image[0].replace('https:', '')}
          />
          <ShareCgOnlineContainer>
            <Billboard
              className='cgonline-detail-bg'
              fluid={[
                bannerBg.childImageSharp.fluid,
                { ...bannerMbBg.childImageSharp.fluid, media: `(max-width: 780px)` },
              ]}
            />
            <Breadcrumb separator='>'>
              <Breadcrumb.Item>
                <LinkToPost>
                  <Link to={`/sharecg/`}>线上分享</Link>
                </LinkToPost>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{title}</Breadcrumb.Item>
            </Breadcrumb>
            <ShareCgOnlineDetailHeader>
              <div className='video-container'>
                {currentDetailData.status === LiveStatus.NOTLIVE && (
                  <img style={{ width: '100%', height: '100%' }} src={image[0]} alt={title} />
                )}
                {currentDetailData.status === LiveStatus.LIVEOVER && (
                  <iframe
                    src={`//player.bilibili.com/player.html?bvid=${currentDetailData.bvid}`}
                    scrolling='no'
                    border='0'
                    frameBorder='no'
                    framespacing='0'
                    allowFullScreen={true}
                    title='bili-live'
                    width='100%'
                    height='100%'
                  ></iframe>
                )}
                {currentDetailData.status === LiveStatus.LIVE && (
                  <div
                    rel='nofollow'
                    onClick={() => window.open(currentDetailData.videoUrl, '_blank')}
                  >
                    <img style={{ width: '100%', height: '100%' }} src={image[0]} alt={title} />
                  </div>
                )}
              </div>
              <BannerContainer>
                <SwiperSwitch
                  navBottom={isMobile ? 46 : -12}
                  interval={2000}
                  hiddenArrowIcon={true}
                  className='guest-swiper'
                >
                  {guests &&
                    guests.map((item) => {
                      return <GuestProfile info={item} key={item.name} />
                    })}
                </SwiperSwitch>
              </BannerContainer>
            </ShareCgOnlineDetailHeader>
            <ShareCgOnlineDetailContent>
              <ShareCgOnlineArticle>
                <ShareCgOnlineArticleHeader>
                  <ShareCgOnlineArticleTitle>{title}</ShareCgOnlineArticleTitle>
                  {!isMobile && (
                    <div>
                      {currentDetailData.status === LiveStatus.LIVE && (
                        <LiveDetailButton
                          rel='nofollow'
                          onClick={() =>
                            window.open(
                              `//player.bilibili.com/player.html?bvid=${currentDetailData.bvid}`,
                              '_blank',
                            )
                          }
                        >
                          点击观看直播
                        </LiveDetailButton>
                      )}
                      {isExsitVisit && (
                        <LiveDetailButton
                          className='visit-btn'
                          onClick={() => window.open(visiturl, '_blank')}
                        >
                          查看专访详情
                        </LiveDetailButton>
                      )}
                    </div>
                  )}
                </ShareCgOnlineArticleHeader>
                <div className='cgonline-content'>
                  <ShareCgOnlineDetailTag>
                    <CgOnlineTag>
                      <TimeIcon />
                      {new Date(publishTime).getFullYear()}
                    </CgOnlineTag>
                    {tag &&
                      tag.map((item) => {
                        return (
                          <CgOnlineTag key={item.title}>
                            {item.icon && <img src={item.icon} alt={item.title} />}
                            {item.title}
                          </CgOnlineTag>
                        )
                      })}
                  </ShareCgOnlineDetailTag>
                  <ShareCgOnlineDetailTime isShowBorder={true}>
                    <div className='specific-time'>
                      直播时间：<span className='hightline'>{specificTime}</span>
                    </div>
                    {isMobile && (
                      <>
                        {currentDetailData.status === LiveStatus.LIVE && (
                          <LiveDetailButton
                            rel='nofollow'
                            onClick={() =>
                              window.open(
                                `//player.bilibili.com/player.html?bvid=${currentDetailData.bvid}`,
                                '_blank',
                              )
                            }
                          >
                            点击观看直播
                          </LiveDetailButton>
                        )}
                        {isExsitVisit && (
                          <LiveDetailButton
                            className='visit-btn'
                            onClick={() => window.open(visiturl, '_blank')}
                          >
                            查看专访详情
                          </LiveDetailButton>
                        )}
                      </>
                    )}
                  </ShareCgOnlineDetailTime>
                  <ShareCgOnlineDetailTime>活动介绍：</ShareCgOnlineDetailTime>
                  {React.createElement('div', {
                    dangerouslySetInnerHTML: { __html: artical.artical },
                  })}
                </div>
              </ShareCgOnlineArticle>
              <ShareCgOnlineSilder>
                <ShareCgOnlineQrcode>
                  <GatsbyImage image={qrcodePic.childImageSharp.gatsbyImageData} alt='' />
                  <div className='qrcode-tip'>
                    关注瑞云官方微信服务号
                    <br />
                    直播福利早知道
                  </div>
                </ShareCgOnlineQrcode>
                <ShareCgOnlineRecommend>
                  {recommendPost &&
                    recommendPost.map((item) => {
                      return (
                        <ShareOnlineDetailContentCard key={item.title}>
                          <OnlineShareCard info={item} />
                        </ShareOnlineDetailContentCard>
                      )
                    })}
                  <ShareCgOnlineRecommedMore onClick={() => navigate(`/sharecg/`)}>
                    查看更多
                  </ShareCgOnlineRecommedMore>
                </ShareCgOnlineRecommend>
              </ShareCgOnlineSilder>
            </ShareCgOnlineDetailContent>
            <ShareCgOnlineDetailFooter>
              <LinkToPost>
                上一篇：
                {previousSlug ? (
                  <Link to={`/sharecg/${previousSlug}.html`}>{previousTitle}</Link>
                ) : (
                  '没有了'
                )}
              </LinkToPost>
              <LinkToPost>
                下一篇：
                {nextSlug ? <Link to={`/sharecg/${nextSlug}.html`}>{nextTitle}</Link> : '没有了'}
              </LinkToPost>
            </ShareCgOnlineDetailFooter>
          </ShareCgOnlineContainer>
        </Layout>
      )}
    />
  )
}

export default ShareCgOnlineDetail

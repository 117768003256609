import styled from 'styled-components'

export const ShareCgOnlineContainer = styled.div`
  width: 1400px;
  margin: 100px auto;
  .cgonline-detail-bg {
    height: 620px;
  }
  .visit-btn {
    border: 1px solid #00ffab;
    background: transparent;
    color: #00ffab;
    margin-right: 0;
    &:hover {
      background: rgb(0, 255, 171);
      color: rgb(33, 37, 42);
    }
  }
  @media (max-width: 768px) {
    width: 90%;
    padding: 18px;
    margin: 120px 0 28px;
  }
`

export const BannerContainer = styled.div`
  background-color: #2b2b37;
  margin-left: 40px;
  width: 440px;
  text-align: center;
  .guest-avatar {
    width: 214px !important;
    height: 214px;
  }
  .guest-swiper {
    padding: 80px 40px 40px;
    .guest-profile {
      margin-top: 0 !important;
      height: 340px;
      width: auto;
    }
    .name {
      color: #ffffff;
    }
  }
  p {
    margin: 0;
  }
  .guest-title {
    color: #ffffff;
    font-size: 24px;
    padding-top: 42px;
  }
  .guest-subTitle {
    color: #ffffff;
    font-size: 16px;
    padding-top: 12px;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    margin-top: 16px;
    .guest-avatar {
      width: 108px !important;
      height: 100px;
    }
    .guest-swiper {
      padding: 38px 40px 60px;

      .guest-profile {
        height: 190px;
        margin: auto;
        width: 100%;
      }
      .position {
        width: 100%;
        font-size: 15px;
      }
      .name {
        font-size: 18px;
      }
    }
    .guest-title {
      font-size: 18px;
      padding-top: 24px;
    }
  }
`

export const ShareCgOnlineDetailHeader = styled.div`
  display: flex;
  height: 520px;
  position: relative;
  flex-direction: row;
  margin-bottom: 40px;
  .video-container {
    cursor: pointer;
    width: 920px;
    height: 520px;
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    height: auto;
    margin-bottom: 15px;

    .video-container {
      cursor: pointer;
      width: 100%;
      height: 192px;
    }
  }
`

export const ShareCgOnlineHeaderContent = styled.div`
  width: 800px;
  height: 240px;
  background: rgb(0, 0, 0, 0.3);
  position: absolute;
  top: 190px;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 4px rgb(0, 255, 171, 0.6) solid;
`
export const ShareCgOnlineSubTitle = styled.h2`
  font-size: 18px;
  color: #ffffff;
  margin-top: 38px;
  margin-right: 56px;
  position: relative;
  line-height: 1;
  ::after {
    content: '';
    position: absolute;
    width: 2px;
    height: 18px;
    background: #ffffff;
    border-radius: 3px;
    right: -28px;
  }
`
export const ShareOnlineDetailContentCard = styled.div`
  margin: 30px 0 0;
  @media (max-width: 768px) {
    margin-top: 20px;
  }
`
export const ShareCgOnlineSilder = styled.div`
  margin-left: 40px;
  @media (max-width: 768px) {
    margin-left: 0;
  }
`
export const ShareCgOnlineDetailFooter = styled.div`
  display: flex;
  flex-direction: column;
`
export const LinkToPost = styled.p`
  color: #ffffff;
  font-size: 14px;
  margin: 0 0 25px;
  a {
    color: #ffffff;
    text-decoration: none;
    border: 0 !important;
    :hover {
      color: #00ffab;
    }
  }
  :hover {
    color: #00ffab;
  }
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`

export const ShareCgOnlineArticle = styled.div`
  background-color: #2b2b37;
  padding: 40px 20px 35px 50px;
  width: 770px;
  flex: 1;
  height: fit-content;
  .cgonline-content {
    margin-right: 20px;
  }
  img {
    max-width: 100%;
  }
  @media (max-width: 768px) {
    width: calc(100% - 40px);
    padding: 20px 20px 32px;
    .cgonline-content {
      margin-right: 0;
    }
  }
`

export const ShareCgOnlineArticleTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
  line-height: 36px;
  margin-right: 40px;
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 28px;
    margin-right: 0;
  }
`
export const ShareCgOnlineArticleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`
export const ShareCgOnlineDetailTime = styled.div`
  font-weight: 400;
  color: #c6c6d2;
  font-size: 14px;
  border-bottom: ${(p) => p.isShowBorder && '1px solid #545460'};
  padding-bottom: 20px;
  margin-top: 20px;
  .hightline {
    color: #00ffab;
    margin-left: 4px;
  }
  @media (max-width: 768px) {
    margin-top: 18px;
    padding-bottom: 18px;
    .specific-time {
      margin-bottom: 15px;
    }
  }
`
export const CgOnlineTag = styled.div`
  background: #383844;
  border-radius: 16px;
  padding: 6px 16px;
  margin-right: 14px;
  color: #c6c6d2;
  font-size: 14px;
  display: flex;
  align-items: center;
  svg,
  img {
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }
  @media (max-width: 768px) {
    padding: 6px 12px;
    font-size: 12px;
    border-radius: 12px;
    margin-right: 10px;
    margin-bottom: 10px;
    svg,
    img {
      width: 12px;
      height: 12px;
      margin-right: 4px;
    }
  }
`
export const ShareCgOnlineDetailTag = styled.div`
  display: flex;
  margin: 20px 0;
  flex-wrap: wrap;
`

export const ShareCgOnlineQrcode = styled.div`
  font-size: 16px;
  color: #ffffff;
  line-height: 24px;
  background-color: #2b2b37;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 30px;
  border-radius: 10px;
  width: 440px;
  .qrcode-tip {
    padding-top: 12px;
    align-items: center;
  }
  @media (max-width: 768px) {
    margin-top: 18px;
    padding-bottom: 22px;
    padding-top: 30px;
    font-size: 14px;
    border-radius: 5px;
    img {
      width: 80px;
      height: 80px;
    }
  }
`
export const ShareCgOnlineRecommend = styled.div``

export const ShareCgOnlineDetailContent = styled.div`
  color: #ffffff;
  margin: auto auto 46px;
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 26px;
  }
`

export const LiveDetailButton = styled.button`
  padding: 0px 22px;
  height: 36px;
  background: #00ffab;
  border-radius: 18px;
  color: #21252a;
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;
  font-size: 16px;
  height: 36px;
  margin-right: 20px;
  border: 0;
  @media (max-width: 768px) {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 15px;
  }
`

export const ShareCgOnlineRecommedMore = styled.div`
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  color: #00ffab;
  text-align: center;
  margin-top: 40px;
`
